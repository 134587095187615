import cashGamesHoldemContentData from './cashGamesHoldemContentData'
import cashGamesShorDeckContentData from './cashGamesShortDeckContentData';
import omahaContentData from './omahaContentData';
import sitAndGoTournamentsContentData from './sitAndGoTournamentsContentData';
import multiStepTournamentsContentData from './multiStepTournamentsContentData';
import vppFreerollsContentData from './vppFreerollsContentData';

export const pokerGamesContentData = [
    cashGamesHoldemContentData,
    cashGamesShorDeckContentData,
    omahaContentData,
    sitAndGoTournamentsContentData,
    multiStepTournamentsContentData,
    vppFreerollsContentData,
];
