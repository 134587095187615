import React, { Fragment } from 'react'
import cx from 'classnames'
import * as s from './s.module.css'
import { pokerGamesNavigationData } from '../navigationData';

export default {
    title: pokerGamesNavigationData[3].title,
    customComponent: () => <Fragment>
        <p className={cx(s.stepParagraph, s.mt_15, s.mb_45)}>
            A Sit and Go, sometimes referred to as a Sit ‘N’ Go or SNG, is a popular type of poker tournament. On this page, we’ll explain the differences between this particular format and cash games. We’ll also take a look at some of the most popular Sit and Go structures.
        </p>
        <p className={s.stepSubtitle}>
            <span className={s.stepSubtitleBlue}>1. </span>What is a Sit and Go?
        </p>
        <p className={s.stepParagraph}>
            As we already alluded to, an SNG is a kind of tournament. As opposed to a cash game, where players can sit down and leave at any time, it is not possible to join a tournament part way through. Chips have no monetary value and once you lose them, you are eliminated.
        </p>
        <p className={s.stepParagraph}>
            As we already alluded to, an SNG is a kind of tournament. As opposed to a cash game, where players can sit down and leave at any time, it is not possible to join a tournament part way through. Chips have no monetary value and once you lose them, you are eliminated.
        </p>
        <p className={s.stepParagraph}>
            The most common Sit and Go format is a Single Table Tournament (STT) with either 9 or 10 players. We’ll talk more about these later on in this article.
        </p>
        <p className={s.stepSubSubtitle}>Fees</p>
        <p className={s.stepParagraph}>
            Unlike with cash games, where a rake is levied on each pot, players must pay a fee up front in order to enter an SNG. Like any other poker tournament, a buy-in is paid which contributes to the total prize pool. But in addition, an entry fee is charged, which is usually a percentage of the buy-in. This is commonly fixed at 10%. For instance, a 20 VPP Sit and Go would typically cost 20 VPP + 2 VPP.
        </p>
        <p className={cx(s.stepSubtitle, s.mt_60)}>
            <span className={s.stepSubtitleBlue}>2. </span>Different Types of Tournament Structure
        </p>
        <p className={s.stepParagraph}>
            Sit and Gos are a wonderfully diverse way to enjoy any form of poker, whether it’s Hold’em Omaha or a Mixed Games format like HORSE. Let’s take a look at some of the most popular SNG structures.
        </p>
        <p className={s.stepSubSubtitle}>Number of Tables</p>
        <p className={s.stepParagraph}>
            When people think of poker tournaments, they usually tend to picture a Multi Table Tournament (MTT). All of the biggest and most prestigious events, such as the World Series of Poker or the World Poker Tour, take place across multiple tables.
        </p>
        <p className={s.stepParagraph}>
            When it comes to SNGs, some certainly do employ an MTT format. But more commonly, they are Single Table Tournaments (STTs). 
        </p>
        <p className={s.stepSubSubtitle}>Number of Players</p>
        <p className={s.stepParagraph}>
            For a Sit and Go to begin, a fixed number of people have to join the event. An STT could simply be a Heads Up match between two opponents. But it could also be a 6-Max game, with six players per table, or a full handed tale with 9 or 10 players.
        </p>
        <p className={s.stepParagraph}>
            Most SNGs are played full handed. Even if there are multiple tables, they tend to feature 9 or 10 players per table. But 6-Max MTTs and even Heads Up MTTs do exist.
        </p>
        <p className={s.stepSubSubtitle}>Buy-Ins</p>
        <p className={s.stepParagraph}>
            We have already explained the fees involved to enter a Sit and Go tournament, with a buy-in plus an entry charge. But not all poker tournaments are identical. A Freezeout is where your initial buy-in earns you a fixed number of tournament chips. Once they are gone, you are out of the event. But some SNGs allow multiple entries to be purchased, known as Rebuys.
        </p>
        <p className={s.stepParagraph}>
            Many regular poker tournaments go even further, allowing players to top up at the end of the rebuy period. And others allow a period of late registration, permitting players to join long after the tournament has begun. This is not the case with Sit and Gos.
        </p>
        <p className={s.stepSubSubtitle}>Blind Increases</p>
        <p className={s.stepParagraph}>
            Sit and Gos, like any other poker tournament, come in a variety of different formats. Longer, slower events tend to favour the more skilled players. While shorter, faster-paced events are better suited to the casual player who just wants to relax, have fun and blow off a little steam.
        </p>
        <p className={s.stepParagraph}>
            A regular SNG tournament structure online would see the blinds increase every 10 minutes or so, with smaller increments between blind sizes. However, a Turbo SNG structure would increase the blinds more frequently, usually in 5 minute increments, with larger jumps between each blind level. Hyper SNGs are structured to be faster still, with even shorter amounts of time between blind levels and even steeper increases.
        </p>
        <p className={s.stepParagraph}>
            Similarly, some tournaments offer a greater depth of starting chips stack. A Deepstack event, as the name implies, would provide a higher number of starting chips than average. This makes the tournament last longer, offering more playability. Turbo and Hyper SNGs tend to have smaller than average starting stack sizes.
        </p>
        <p className={cx(s.stepSubtitle, s.mt_60)}>
            <span className={s.stepSubtitleBlue}>3. </span>Sit and Go Top Tips
        </p>
        <p className={s.stepParagraph}>
            Now that you know all about Sit and Gos, it's time to get involved and play some poker. Here are some top tips to help you improve your SNG strategy.
        </p>
        <ul className={cx(s.list, s.mb_60)}>
            <li className={s.listItemNoPoint}>
                <span>Experiment with structures:</span> As we've explained above, SNGs come in many different flavours. Not all of them will be suited to your own individual style of play. Splash around at lower stakes to find out what works for you, then look to specialise in that format.
            </li>
            <li className={s.listItemNoPoint}>
                <span>Preservation is key:</span> With an SNG, once those chips are gone, it’s game over. The fewer chips you have, the more valuable they become. Don’t call and raise as liberally with weaker holdings as you might do in a cash game.
            </li>
            <li className={s.listItemNoPoint}>
                <span>Play to win:</span> As with any poker tournament, the prize structure of a Sit and Go heavily favours the winner. Whoever finishes in first place will take the lion’s share of the prize pool. A minimum cash is a nice consolation prize, but having invested all that time into a Sit ‘N’ Go, you want to maximise your winnings.
            </li>
            <li className={s.listItemNoPoint}>
                <span>Monitor your stack:</span> The dynamics of an SNG are fluid. If you become a chip leader early on, you can loosen up and put pressure on the smaller stacks. If you’re in trouble, you need to tighten up. As time progresses and the blinds increase, the need for urgency will also vary. Pay attention to your chip stack in relation to the blinds and to others in the tournament. It will shape your strategy at any given moment.
            </li>
        </ul>
    </Fragment>,
    navigationreferenceid: `#${pokerGamesNavigationData[3].id}`,
};