import React, { Fragment } from 'react'
import cx from 'classnames'
import * as s from './s.module.css'
import { pokerGamesNavigationData } from '../navigationData';

export default {
    title: "What are Multi Step Tournaments",
    customComponent: ({ onMenuBtnClick }) => {
                return (<Fragment>
                <p className={cx(s.stepParagraph, s.mt_15, s.mb_15)}>
                    Multi-Step tournaments often referred to as MSTs are a variant of <span className={s.stepLink} onClick={() => onMenuBtnClick(`#${pokerGamesNavigationData[3].id}`)}>sit and go's</span>. MSTs allow
                    you to play for a bigger prizes over multiple days.
                </p>
                <p className={s.stepParagraph}>
                    Multi-step tournaments have the following structure:
                </p>
                <ul className={s.list}>
                    <li className={s.listItem}>
                        Satellites that generally cost between 25-50 VPP that players can compete for throughout the week
                    </li>
                    <li className={s.listItem}>
                        Winners compete on Sunday and a Semi-Final Sit and Go. The winners advance to the Final Table
                    </li>
                    <li className={s.listItem}>
                        The Final Table is played immediately after the Semi-Final Table and plays down to a winner.
                    </li>
                </ul>
                <p className={cx(s.stepSubtitle, s.mt_30)}>Weekly MSTs</p>
                <p className={s.stepParagraph}>
                    <span className={s.bold} >50 VPP $1000 USD added Multi-Step Tournament</span>
                </p>
                <p className={s.stepParagraph}>
                    Our weekly 50 VPP buy-in MST this week will have $1,000 USD added split across the four
                    finalists.
                </p>
                <p className={cx(s.stepParagraph, s.ml_30)}>
                    1st: 500 USD<br/>
                    2nd: 250 USD<br/>
                    3rd: 150 USD<br/>
                    4td: 100 USD<br/>
                </p>
                <p className={s.stepParagraph}>
                    <span className={s.bold} >Format:</span>
                </p>
                <p className={cx(s.stepParagraph, s.ml_30, s.mb_45)}>
                    4-max hyper - Satellite<br/>
                    6-max turbo - Semi-Final (Sunday)<br/>
                    6-max turbo - Final (Sunday)<br/>
                </p>
            </Fragment>);
        },
    navigationreferenceid: `#${pokerGamesNavigationData[4].id}`,
};