import React from "react";
import Layout from "../layout/default";
import Seo from "../components/seo";
import HowToPlay from "../components/HowToPlay";

const HowToPlayPage = ({ location, pageContext }) => (
    <Layout location={location} dynamicDefaultPaths={pageContext.dynamicDefaultPaths}>
        <Seo title="How To Play" />
        <HowToPlay />
    </Layout>
)

export default HowToPlayPage;
