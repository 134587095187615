import React, { Fragment } from 'react'
import cx from 'classnames'
import * as s from './s.module.css'
import { pokerGamesNavigationData } from '../navigationData';
import { Link } from 'gatsby'

export default {
    title: 'What are VPP Freerolls',
    customComponent: () => <Fragment>
        <p className={cx(s.stepParagraph, s.mt_15, s.mb_45)}>
            In poker, a freeroll tournament is a tournament with no entry fee. A freeroll is a tournament where players have a chance to win free
            <Link className={s.stepLink} to={`/tokeneconomy/?tab=tab1`}>&nbsp;Virtue Player Points</Link>.
        </p>
        <p className={cx(s.stepParagraph, s.mt_15, s.mb_60)}>
            From time to time, Virtue Poker will offer a number of freeroll tournaments, remember to regularly check the promotions tab within the client or follow us on
            <a className={s.stepLink} target="_blank" href="https://twitter.com/Virtue_Poker">
                {` Twitter `}
            </a>
            and 
            <a className={s.stepLink} target="_blank" href="https://t.me/virtue_poker">
            {` Telegram `}
            </a>
            to be the first to register. 
        </p>
    </Fragment>,
    navigationreferenceid: `#${pokerGamesNavigationData[5].id}`,
};