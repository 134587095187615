import React from 'react';
import MSTable from './MSTable';
import * as s from './s.module.css';
import { pokerGamesNavigationData } from './navigationData';
import { pokerGamesContentData } from './Contents/contents';
import Title from '../Title';

interface State {
  id: String,
}

class HowToPlay extends React.Component<{}, State> {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
    }
  }

  checkId = (navigationData: any, id: any) => {
    const list = [];
    navigationData.map((obj: { id: String, subtitles?: Array<any>}) => {
        if (obj.subtitles && obj.subtitles.length) {
            obj.subtitles.map( sub => {
                list.push(`#${sub.id}`)
            })
        }
        list.push(`#${obj.id}`);
    })
    return list.includes(id);
  }

  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    window.history.replaceState('','','/howtoplay');
    let id = '';
    const idInParam = params.get('step');
    if (idInParam) {
      id = `#${idInParam}`;
    }
    if (this.checkId(pokerGamesNavigationData, id)) {
      this.setState({ id: id });
    }
  }

  render() {
    return (
      <section className={s.sectionContainer}>
        <div className={s.sectionContent}>
          <Title>How to Play</Title>
          <MSTable navigationData={pokerGamesNavigationData} contentData={pokerGamesContentData} id={this.state.id}/>
        </div>
      </section>
    )
  }
}

export default HowToPlay;