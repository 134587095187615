export const pokerGamesNavigationData = [{
    title: "Guide to Texas Hold’em",
    id: 'step0',
  }, {
    title: `Guide to Short Deck Hold'em`,
    id: 'step1',
  }, {
    title: "Guide to Omaha",
    id: 'step2',
  }, {
    title: "Guide to Sit and Go Tournaments",
    id: 'step3',
  }, {
    title: "Multi Step Tournaments",
    id: 'step4',
  }, {
    title: "VPP Freerolls",
    id: 'step5',
  },
];
