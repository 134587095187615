import React, { Fragment } from 'react'
import cx from 'classnames'
import * as s from './s.module.css'
import { pokerGamesNavigationData } from '../navigationData';

export default {
    title: pokerGamesNavigationData[0].title,
    customComponent: () => {
    // customComponent: ({ fluidImgList }) => {
        return (<Fragment>
            <p className={cx(s.stepParagraph, s.mt_15, s.mb_45)}>
                Texas Hold’em, or simply Hold’em for short, is by far the most popular form of poker. On this
                page, we’ll be looking at Hold’em cash games, as opposed to tournaments. We’ll talk about
                everything from the rules of the game, including Hold’em hand rankings, to the various
                betting limits available.
            </p>
            <p className={s.stepSubtitle}>
                <span className={s.stepSubtitleBlue}>1. </span>What is a Cash Game?
            </p>
            <p className={s.stepParagraph}>
                In any form of poker, a cash game is one involving chips with an actual monetary value. In a
                poker tournament, everyone starts with the same number of chips and whoever is left with
                them all wins the top prize. But in a cash game, you can buy as many chips as you like and
                leave whenever you see fit.
            </p>
            <p className={s.stepSubSubtitle}>Rake</p>
            <p className={s.stepParagraph}>
                Poker rooms rarely spread a cash game for free. Most will take a cut of any pot over a
                certain size, in order to cover their running costs. This is known as the rake. It differs from a
                tournament entry fee, which is a fixed sum taken at the start of the event.
            </p>
            <p className={cx(s.stepSubtitle, s.mt_60)}>
                <span className={s.stepSubtitleBlue}>2. </span>Betting Limits
            </p>
            <p className={s.stepParagraph}>
                Texas Hold’em comes in three different forms. The most popular, as seen in many of the world’s top
                tournaments and the biggest cash games, is No Limit Hold’em. But Limit versions exist too.
            </p>
            <ul className={s.list}>
                <li className={s.listItemNoPoint}>
                    <span>Fixed Limit Hold’em:</span> Sometimes called small bet poker, players can
                    only bet a fixed amount each time. Pre- and post-flop, that amount is equal to the Big Blind. While on the
                    turn and river, it doubles. So if the game is 2 VPP/4 VPP Limit Hold’em, the blinds are set at 2 VPP and 4 VPP
                    and the bet limits are 4 VPP and 8 VPP.
                </li>
                <li className={s.listItemNoPoint}>
                    <span>Pot Limit Hold’em:</span> Unlike in small bet poker, in Pot Limit, you can
                    bet as much as you like. As long as it doesn’t exceed the total amount in the pot, that is. So naturally
                    as a hand progresses and money is wagered, the upper limit steadily increases.
                </li>
                <li className={s.listItemNoPoint}>
                    <span>No Limit Hold’em:</span> As the name implies, there are no betting limits at all.
                    You can bet whatever is in front of you, as long as it’s your turn to act. No Limit and Pot Limit Hold’em are
                    collectively known as big bet games.
                </li>
            </ul>
            <p className={cx(s.stepSubtitle, s.mt_60)}><span className={s.stepSubtitleBlue}>3. </span>Hold’em Rules</p>
            <p className={s.stepParagraph}>
                They say that it takes minutes to learn the rules of Texas Hold’em, but a lifetime to master the game. We can’t turn
                you into a Hold’em champion overnight. But we can at least explain the basic rules.
            </p>
            <ul className={s.list}>
                <li className={s.listItem}>
                    Every player receives two cards face down, which are private.
                </li>
                <li className={s.listItem}>
                A round of betting takes place, before three community cards are turned face up in the middle of the table. All players can use these and it is known as the flop.
                </li>
                <li className={s.listItem}>
                Another round of betting occurs before a fourth card is dealt, called the turn.
                </li>
                <li className={s.listItem}>
                More betting takes place before the fifth and final card - the river - is revealed.
                </li>
                <li className={s.listItem}>
                A final round of betting happens before all remaining players show their cards and the winner is determined.
                </li>
                <li className={s.listItem}>
                If at any point in the hand you force everyone else to fold, or throw their hand away, you will win the pot regardless of your hand.
                </li>
                <li className={s.listItem}>
                All poker hands are made up of five cards. Even though there can be as many as seven to work with, you only ever use five.
                </li>
                <li className={s.listItem}>
                If someone places a bet, you must at least match that amount - a call - to stay in the hand. If you really like what you have, you can opt to increase the stakes with a raise.
                </li>
                <li className={s.listItem}>
                Every hand, two players will be forced to put money into the pot before they even see their cards. These are known as the blinds. Their purpose is to generate action.
                </li>
            </ul>
            <p className={s.stepSubSubtitle}>Hand Rankings</p>
            <p className={s.stepParagraph}>
                If the action reaches the showdown stage, how do we determine which player wins the hand? Simple. We refer to the Hold’em hand rankings chart below.
            </p>
            <div className={cx(s.tableContainer, s.mb_45)}>
                <div className={cx(s.myTable, s.alternateColorOdd, s.round, s.bt,s.bb,s.br, s.bl)}>
                    <div className={cx(s.rowHeader, s.roundUpper)}>
                        <div className={s.w_25}><p className={s.textLeft}>Bonuses</p></div>
                        <div className={s.separator}></div>
                        <div className={s.w_50}><p className={s.textLeft}>Description</p></div>
                        <div className={s.separator}></div>
                        <div className={s.w_25}><p>Example</p></div>
                    </div>
                    <div className={s.row}>
                        <div className={s.w_25}><p className={s.textLeft}>Straight Flush</p></div>
                        <div className={s.w_50}><p className={s.textLeft}>Five suited cards in a straight line</p></div>
                        <div className={s.w_25}><p>As Ks Qs Js Ts</p></div>
                    </div>
                    <div className={s.row}>
                        <div className={s.w_25}><p className={s.textLeft}>Four of a Kind</p></div>
                        <div className={s.w_50}><p className={s.textLeft}>Four cards with matching index, plus an unconnected fifth car</p></div>
                        <div className={s.w_25}><p>Th Ts Tc Td Kc</p></div>
                    </div>
                    <div className={s.row}>
                        <div className={s.w_25}><p className={s.textLeft}>Full House</p></div>
                        <div className={s.w_50}><p className={s.textLeft}>Three of the same index plus a pair</p></div>
                        <div className={s.w_25}><p>Qh Qc Qs 9d 9s</p></div>
                    </div>
                    <div className={s.row}>
                        <div className={s.w_25}><p className={s.textLeft}>Flush</p></div>
                        <div className={s.w_50}><p className={s.textLeft}>Any five suited cards, not in a line</p></div>
                        <div className={s.w_25}><p>Ac Jc Tc 6c 2c</p></div>
                    </div>
                    <div className={s.row}>
                        <div className={s.w_25}><p className={s.textLeft}>Straight</p></div>
                        <div className={s.w_50}><p className={s.textLeft}>Any five offsuit cards in a line</p></div>
                        <div className={s.w_25}><p>Js Tc 9d 8h 7c</p></div>
                    </div>
                    <div className={s.row}>
                        <div className={s.w_25}><p className={s.textLeft}>Three of a Kind</p></div>
                        <div className={s.w_50}><p className={s.textLeft}>Three cards with the same index alongside two unpaired cards</p></div>
                        <div className={s.w_25}><p>Ks Kc Kd 9s 4h</p></div>
                    </div>
                    <div className={s.row}>
                        <div className={s.w_25}><p className={s.textLeft}>Two Pair</p></div>
                        <div className={s.w_50}><p className={s.textLeft}>Two different sets of paired cards with a random unconnected card</p></div>
                        <div className={s.w_25}><p>8h 8c 4d 4h Js</p></div>
                    </div>
                    <div className={s.row}>
                        <div className={s.w_25}><p className={s.textLeft}>One Pair</p></div>
                        <div className={s.w_50}><p className={s.textLeft}>A pair with matching indices plus three unconnected cards</p></div>
                        <div className={s.w_25}><p>5d 5s Kc Jc 3h</p></div>
                    </div>
                    <div className={s.row}>
                        <div className={s.w_25}><p className={s.textLeft}>High Card</p></div>
                        <div className={s.w_50}><p className={s.textLeft}>Five random cards that don’t fit into any other category</p></div>
                        <div className={s.w_25}><p>Ks Qh 7c 4s 2d</p></div>
                    </div>
                </div>
            </div>
            <p className={s.stepSubSubtitle}>Cash Games Rules</p>
            <p className={s.stepParagraph}>
                So far, we’ve covered the basic rules of Texas Hold’em. These apply to both cash games or tournaments. But there are some oddities that are unique to cash games.
            </p>
            <ul className={s.list}>
                <li className={s.listItemNoPoint}>
                    <span>Straddle:</span> The exact rules of a straddle will depend on the host of the game. But the classic straddle is when the player first to act - under the gun - voluntarily posts a third blind. It is usually twice the size of the Big Blind and must be posted before looking at the cards. They will now close the preflop betting action, instead of the Big Blind
                </li>
                <li className={s.listItemNoPoint}>
                    <span>Table Stakes:</span> Whatever is in front of you at the start of the hand is your upper betting limit. This prevents the unethical and dangerous practices seen in the movies, where watches and car keys end up in the middle!
                </li>
                <li className={s.listItemNoPoint}>
                    <span>Buy-Ins:</span> Again, the exact rules around buying into a Hold’em cash game will depend on the host. But there is always a minimum amount, often between 10 and 20 BBs, and a maximum amount.
                </li>
                <li className={s.listItemNoPoint}>
                    <span>Sitting Out:</span> Players can leave the table temporarily, in order to take a quick break. When sitting out, they do not need to post blinds, like they would in a tournament. However, to prevent cheating, if a player sits out and misses a blind, they must post it upon their return. This applies even if they are not actually in the blinds.
                </li>
            </ul>
            <p className={cx(s.stepSubtitle, s.mt_60)}>
                <span className={s.stepSubtitleBlue}>3. </span>A Hand of Hold’em
            </p>
            <p className={s.stepParagraph}>
                To illustrate how a Texas Hold’em cash game hand unfolds, let’s look at an example. Let’s say there are six players at the table and the game is 1 VPP/2 VPP No Limit Hold’em.
            </p>
            <p className={s.stepSubSubtitle}>Preflop</p>
            <p className={s.stepParagraph}>
                Everyone is dealt two cards, starting with the player seated to the left of the dealer button. This player also posts the smaller of the two blinds. The player to the left of the Small Blind posts the Big Blind. Upon receiving two cards each, the player to the left of the Big Blind (known as under the gun) is first to act.
            </p>
            <p className={s.stepParagraph}>
                The player under the gun elects to fold. They throw away their cards and so they are finished with this hand. Player 4 also elects to fold and Player 5 chooses to raise. They make it up to 6 VPP with a 4 VPP increase. Player 6 is the one seated on the button and they opt to fold. 
            </p>
            <p className={s.stepParagraph}>
                It’s now the turn of the Small Blind, who also decides to fold. The 1 VPP blind posted earlier is lost and remains in the pot. The Big Blind calls, adding 4 VPP to the 2 VPP they already had in the middle, to match the total of 6 VPP.
            </p>
            <p className={s.stepSubSubtitle}>Flop</p>
            <p className={s.stepParagraph}>
                Play always begins from the left of the dealer button. That would be the Small Blind, but they have already folded. So in this case, it’s the player in the Big Blind. They opt to check, or in other words, they decline to bet.
            </p>
            <p className={s.stepParagraph}>
                Player 5 is the only other player left to act. They make a bet of 10 VPP. The Big Blind elects to call this bet, meaning they also put in 10 VPP and the betting is complete.
            </p>
            <p className={s.stepSubSubtitle}>Turn</p>
            <p className={s.stepParagraph}>
            After the turn card is dealt, the Big Blind once again checks. Player 5 can either check as well, and we would go to the river card, or they can choose to bet as they did on the flop. This time, the player elects to check. This closes the action and we will see the river card.
            </p>
            <p className={s.stepSubSubtitle}>River</p>
            <p className={s.stepParagraph}>
            The Big Blind checks. Player 5 bets 20 VPP. The Big Blind now opts to raise it up to a total of 60 VPP - a check-raise! If Player 5 wants to continue, they must put in another 40 VPP. Or they can fold and the Big Blind will take the pot. The other option would be to re-raise and add even more to the pot.
            </p>
            <p className={s.stepParagraph}>
            The player opts to simply call, which ends the action and we go to a showdown.
            </p>
            <p className={s.stepSubSubtitle}>Showdown</p>
            <p className={s.stepParagraph}>
                The Big Blind must show first, as they are left of the dealer. The player turns over two pair. In order for Player 5 to win the pot, their hand must be better than two pair. They turn over three of a kind, which does beat two pair and they scoop the pot worth 153 VPP.
            </p>
            <p className={cx(s.stepSubtitle, s.mt_60)}>
                <span className={s.stepSubtitleBlue}>4. </span>Hold’em Cash Game Tips
            </p>
            <p className={s.stepParagraph}>
                Having covered all there is to know about Texas Hold’em cash games, here’s a few tips to get you started:
            </p>
            <ul className={s.list}>
                <li className={s.listItemNoPoint}>
                    <span>Bet with a good hand:</span> Of course, poker involves a lot of psychology and deception. Sometimes it pays to bluff, often it helps to feign weakness. But for the most part, when you have a good hand, you should bet. When you are the favourite to win, you need to get money into the middle. There's no point winning a pot if it’s empty.
                </li>
                <li className={s.listItemNoPoint}>
                    <span>Check often when many players are in the hand:</span> The more people involved in a pot, the better your own hand needs to be. Against one opponent, top pair is a very strong hand. But against four players, the chance of there being two pair, three of a kind or some kind of draw is greatly increased.
                </li>
                <li className={s.listItemNoPoint}>
                    <span>It requires a stronger hand to call than it does to bet:</span> Anyone can bet or raise, representing a good hand. The opponent may be bluffing, but you’ll have to pay to find out. There is little point in calling a bet with complete trash when your opponent is telling you they have something. 
                </li>
                <li className={cx(s.listItemNoPoint, s.mb_45)}>
                    <span>Keep an eye on your position:</span> The play always moves clockwise from the dealer button. If you act later in the hand, you have the privilege of seeing what everyone else has done ahead of you. This makes it easier to decide, as you have more information. Playing out of position is hard, so try to do so only with strong hands.
                </li>
            </ul>
            {/* {fluidImgList.length && <Img className={cx(s.stepImg, { [s.overlay]: fluidImgList[0].overlay })} fluid={fluidImgList[0].fluidImg} />} */}
        </Fragment>);
    },
    // imgList: [
    //     { name: 'click-cashier', overlay: true },
    //     { name: 'click-deposit', overlay: true },
    // ],
    navigationreferenceid: `#${pokerGamesNavigationData[0].id}`,
};