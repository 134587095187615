import React, { Fragment } from 'react'
import cx from 'classnames'
import * as s from './s.module.css'
import { pokerGamesNavigationData } from '../navigationData';

export default {
    title: pokerGamesNavigationData[2].title,
    customComponent: () => <Fragment>
        <p className={cx(s.stepParagraph, s.mt_15, s.mb_30)}>
            The exact origins of Omaha are unclear, though it’s believed that this interesting poker game is derived from Texas Hold’em. But with four cards instead of two and a requirement to use exactly two from your hand, the games couldn't be more different in terms of strategy. Let’s take a look at everything there is to know about Omaha poker.
        </p>
        <p className={s.stepSubtitle}><span className={s.stepSubtitleBlue}>1. </span>Different Types of Omaha</p>
        <p className={s.stepParagraph}>
            Before we get into too much detail, it’s worth clarifying that there are several different ways to play Omaha. First of all, there are three different betting limit variations, which we’ll come to shortly. But secondly, there are actually two popular forms of Omaha.
        </p>
        <p className={s.stepParagraph}>
            Regular Omaha, or Omaha Hold’em, is the game we’ll focus on for the bulk of this article. But there also exists a split pot variation. In this game, half the pot is like traditional Omaha but the other is awarded to the player with the best low hand. This is known as Omaha Eight or better, or Omaha High/Low Split. We’ll talk about it in more detail later in the article.
        </p>
        <p className={s.stepSubSubtitle}>Betting Limits</p>
        <p className={s.stepParagraph}>
            Just like Omaha’s cousin Texas Hold’em, the game can be played at a variety of different limits.
        </p>
        <ul className={s.list}>
            <li className={s.listItemNoPoint}>
                <span>Fixed Limit Omaha:</span>  As the name implies, the bet sizes in this format are fixed. Before and after the flop, all bets must be equal to the Big Blind. But this doubles for bets on the turn and river. For instance, if the game is 1 VPP/2 VPP, then the betting limits are fixed at 2 VPP and 4 VPP.
            </li>
            <li className={s.listItemNoPoint}>
                <span>Pot Limit Omaha:</span>  The most common form of Omaha is Pot Limit. Here you can bet any amount up to the size of the pot. As more money enters the pot, the maximum bet increase throughout the hand.
            </li>
            <li className={s.listItemNoPoint}>
                <span>No Limit Omaha:</span>  On occasion, you will see No Limit games of Omaha. Exactly like Hold’em, you can bet any amount you wish up to the value of your total stack. Be careful with these games if you’re new to Omaha!
            </li>
        </ul>
        <p className={cx(s.stepSubtitle, s.mt_60)}><span className={s.stepSubtitleBlue}>2. </span>Omaha Rules</p>
        <p className={s.stepParagraph}>
            So let’s now jump into the rules of the game. If you’re already familiar with Texas Hold’em, you’re off to a good start as there are many similarities. Here are the basics.
        </p>
        <ul className={s.list}>
            <li className={s.listItem}>
                All players are dealt four cards face down, which remain unknown to anyone else.
            </li>
            <li className={s.listItem}>
                The initial betting round takes place before three cards are dealt face up in the centre of the table. This is called the flop and all players are entitled to use these cards.
            </li>
            <li className={s.listItem}>
                A new betting round occurs, ahead of a fourth card - the turn - being revealed.
            </li>
            <li className={s.listItem}>
                Further betting takes place before the river card, the fifth and final card, is dealt.
            </li>
            <li className={s.listItem}>
                One last betting round takes place after the river and anyone left in the pot must now reveal their hand to determine the winner.
            </li>
            <li className={s.listItem}>
                From these five shared cards and your own four cards, you must make a five card poker hand in accordance with the usual hand rankings (see below). However, you <span className={s.listItemBold}>must</span> use two of your own cards and three from the community cards.
            </li>
            <li className={s.listItem}>
                If during the hand you can force all other players to fold, or discard their hands, you keep the pot no matter the strength of your hand.
            </li>
            <li className={s.listItem}>
                If a bet is placed, then you cannot continue until you match that wager, known as a call. You could also elect to raise, by increasing the total bet size further.
            </li>
            <li className={s.listItem}>
                Ahead of every hand, the two players closest to the dealer button must pay money into the pot without looking at their cards. These forced bets are called the blinds and they are what get the action going.
            </li>
            <li className={s.listItem}>
                Aces play as both high and low, therefore A-2-3-4-5 is a straight, as is T-J-Q-K-A
            </li>
        </ul>
        <p className={s.stepSubSubtitle}>Hand Rankings</p>
        <p className={s.stepParagraph}>
            In Omaha, the hand rankings work just the same way as Hold’em. You may have more cards to work with, but a poker hand is always made up of five cards. See the below table for details.
        </p>
        <div className={cx(s.tableContainer, s.mb_45)}>
                <div className={cx(s.myTable, s.alternateColorOdd, s.round, s.bt,s.bb,s.br, s.bl)}>
                    <div className={cx(s.rowHeader, s.roundUpper)}>
                        <div className={s.w_25}><p className={s.textLeft}>Hand Name</p></div>
                        <div className={s.separator}></div>
                        <div className={s.w_50}><p className={s.textLeft}>Description</p></div>
                        <div className={s.separator}></div>
                        <div className={s.w_25}><p>Example</p></div>
                    </div>
                    <div className={s.row}>
                        <div className={s.w_25}><p className={s.textLeft}>Straight Flush</p></div>
                        <div className={s.w_50}><p className={s.textLeft}>Five cards in a straight line, all of the same suit</p></div>
                        <div className={s.w_25}><p>Ah Kh Qh Jh Th</p></div>
                    </div>
                    <div className={s.row}>
                        <div className={s.w_25}><p className={s.textLeft}>Four of a Kind</p></div>
                        <div className={s.w_50}><p className={s.textLeft}>Four of the same index, plus any random fifth card</p></div>
                        <div className={s.w_25}><p> Jh Js Jc Jd 7c</p></div>
                    </div>
                    <div className={s.row}>
                        <div className={s.w_25}><p className={s.textLeft}>Full House</p></div>
                        <div className={s.w_50}><p className={s.textLeft}>Three of a kind plus a pair</p></div>
                        <div className={s.w_25}><p>Kh Kd Ks 2c 2s</p></div>
                    </div>
                    <div className={s.row}>
                        <div className={s.w_25}><p className={s.textLeft}>Flush</p></div>
                        <div className={s.w_50}><p className={s.textLeft}>Any five of the same suit, not in a line</p></div>
                        <div className={s.w_25}><p>Kd Jd 8d 3d 2d</p></div>
                    </div>
                    <div className={s.row}>
                        <div className={s.w_25}><p className={s.textLeft}>Straight</p></div>
                        <div className={s.w_50}><p className={s.textLeft}>Any five cards in a line, not of the same suit</p></div>
                        <div className={s.w_25}><p>8h 7c 6d 5h 4s</p></div>
                    </div>
                    <div className={s.row}>
                        <div className={s.w_25}><p className={s.textLeft}>Three of a Kind</p></div>
                        <div className={s.w_50}><p className={s.textLeft}>Any three of the same index with two random unpaired cards</p></div>
                        <div className={s.w_25}><p>9h 9c 9d 6s 3d</p></div>
                    </div>
                    <div className={s.row}>
                        <div className={s.w_25}><p className={s.textLeft}>Two Pair</p></div>
                        <div className={s.w_50}><p className={s.textLeft}>Two pairs of matching indices with a fifth random card</p></div>
                        <div className={s.w_25}><p>Jh Js 6d 6c Ks</p></div>
                    </div>
                    <div className={s.row}>
                        <div className={s.w_25}><p className={s.textLeft}>One Pair</p></div>
                        <div className={s.w_50}><p className={s.textLeft}>A single pair of the same index with any three random cards</p></div>
                        <div className={s.w_25}><p>Qh Qs Ah 9s 4c</p></div>
                    </div>
                    <div className={s.row}>
                        <div className={s.w_25}><p className={s.textLeft}>High Card</p></div>
                        <div className={s.w_50}><p className={s.textLeft}>Five unconnected cards that don’t form any of the above combinations</p></div>
                        <div className={s.w_25}><p>Ah Js 8c 7s 3c</p></div>
                    </div>
                </div>
            </div>
        <p className={s.stepSubSubtitle}>
            Omaha Eight or Better Rules
        </p>
        <p className={s.stepParagraph}>
            In this version of the game, sometimes called Omaha 8, Omaha Hi-Lo or High/Low Split, there are two pots. Play unfolds in exactly the same way as Omaha, only at the end of the hand, two winners are determined. One takes the normal, or high half of the pot and the other takes the low half. We already know how to determine the winner of the high pot. But what happens with the low?
        </p>
        <p className={s.stepParagraph}>
            A low is defined as any five unpaired cards which are 8 or lower. So any hand worth 8 high or worse is a low hand. Straights and flushes do not count for purposes of making a low and aces play as both high and low, just like regular Omaha.
        </p>
        <p className={s.stepParagraph}>
            So the best possible low is therefore A-2-3-4-5, as it is five high. Yes it is also a straight, which is great for the high part of the hand too. But for low purposes, it is classed as five high. The worst possible low hand would be 8-7-6-5-4.
        </p>
        <p className={s.stepParagraph}>
            When you both hold the same low, you move to the second card in the sequence, then the third and so on. So for example, a 7-6-4-3-A low would beat a 7-6-5-2-A low, as the 4 is lower than the 5.
        </p>
        <p className={s.stepSubSubtitle}>
            Example
        </p>
        <p className={s.stepParagraph}>
            Let’s say that you are holding A-A-6-7 on a board of K-4-5-Q-8. Your opponent has K-K-2-3. Who wins what in this situation? 
        </p>
        <p className={cx(s.stepParagraph, s.mb_45)}>
            First we look at the high hand. You have made a straight (4-5-6-7-8) which beats the opponent's hand of three Kings. So you automatically take half of the pot for having the best high hand. In terms of the low pot, your straight also acts as an 8 low. But unfortunately your opponent has a better 8 low as 8-5-4-3-2 beats 8-7-6-5-4.
        </p>
    </Fragment>,
    navigationreferenceid: `#${pokerGamesNavigationData[2].id}`,
};