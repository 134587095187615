import React, { Fragment } from 'react'
import cx from 'classnames'
import * as s from './s.module.css'
import { pokerGamesNavigationData } from '../navigationData';

export default {
    title: pokerGamesNavigationData[1].title,
    customComponent: () => <Fragment>
        <p className={cx(s.stepParagraph, s.mt_15, s.mb_45)}>
            Sometimes referred to as Six Plus, Short Deck Hold’em is based on the original Texas Hold’em game. However, there’s a rather large twist, in that all of the lower value cards are removed from the deck. The result is a faster paced, action packed game, as players are dealt playable hands more frequently. Let’s learn more about Short Deck Hold’em, specifically cash games.
        </p>
        <p className={s.stepSubtitle}>
            <span className={s.stepSubtitleBlue}>1. </span>What is a Cash Game?
        </p>
        <p className={s.stepParagraph}>
            As opposed to a poker tournament, a cash game allows players to join in and leave as and when they like. The chips on the table carry to real monetary value, unlike tournament chips which are solely used to determine prize winners.
        </p>
        <p className={s.stepSubSubtitle}>Rake</p>
        <p className={s.stepParagraph}>
            While poker tournaments charge fixed fees before the event gets underway, cash games do not. However, the poker room still has to cover its expenses and they do this in the form of a rake. Every pot that reaches a certain size will have a small percentage removed, which is called the rake.
        </p>
        <p className={cx(s.stepSubtitle, s.mt_60)}>
            <span className={s.stepSubtitleBlue}>2. </span>What is Short Deck Hold’em?
        </p>
        <p className={s.stepParagraph}>
            Short Deck Hold’em or Six Plus (6+) Hold’em originated in Asia. The likes of Paul Phua, of Triton Poker fame, wanted to create a game with even more action than No Limit Hold’em. His clique of high rolling business people and poker players hit upon the idea of removing the least valuable cards from the regular deck.
        </p>
        <p className={s.stepParagraph}>
            The thought process is simple. Constantly folding is not fun. If you’re an amateur player buying into the highest stakes games, you want action. You want to enjoy your money’s worth. Sitting for long periods of time staring at junk is boring. And what’s even less fun, is being constantly outplayed by superior opponents who are better at playing tricky hands.
        </p>
        <p className={s.stepParagraph}>
            In order to stimulate action, the group opted to ditch all of the cards lower than a 6. So in Short Deck, there are no 2s, 3s, 4s or 5s. The new deck contains 36 cards and as a result, your traditional big Hold’em hands come around way more often. That means more playability and more chance of actually winning a pot.
        </p>
        <p className={cx(s.stepSubtitle, s.mt_60)}>
            <span className={s.stepSubtitleBlue}>3. </span>Rules
        </p>
        <p className={s.stepParagraph}>
        The rules of Short Deck are exactly like that of Texas Hold’em, only with a slight difference in the hand rankings. Since there are four fewer cards in the deck with which to make a flush, this type of hand is harder to hit. As a result, it jumps up the ranking ladder. We’ve listed out the hand rankings in full below.
        </p>
        <p className={s.stepParagraph}>
        The rules of Short Deck are exactly like that of Texas Hold’em, only with a slight difference in the hand rankings. Since there are four fewer cards in the deck with which to make a flush, this type of hand is harder to hit. As a result, it jumps up the ranking ladder. We’ve listed out the hand rankings in full below.
        </p>
        <p className={s.stepParagraph}>
        The only other exception to the traditional rules of Hold’em concerns the low end of a straight. In traditional Hold’em, the ace can play as a low card, meaning A-2-3-4-5 forms a complete hand. Despite Short Deck having no 2 through 5 cards, you can still make a wheel using an ace: A-6-7-8-9 becomes a legal straight.
        </p>
        <p className={s.stepSubSubtitle}>Hand Rankings</p>
        <div className={cx(s.tableContainer, s.mb_45)}>
                <div className={cx(s.myTable, s.alternateColorOdd, s.round, s.bt,s.bb,s.br, s.bl)}>
                    <div className={cx(s.rowHeader, s.roundUpper)}>
                        <div className={s.w_50}><p className={s.textLeft}>Hand Name</p></div>
                        <div className={s.separator}></div>
                        <div className={s.w_50}><p>Example</p></div>
                    </div>
                    <div className={s.row}>
                        <div className={s.w_50}><p className={s.textLeft}>Straight Flush</p></div>
                        <div className={s.w_50}><p>Ac Kc Qc Jc Tc</p></div>
                    </div>
                    <div className={s.row}>
                        <div className={s.w_50}><p className={s.textLeft}>Four of a Kind</p></div>
                        <div className={s.w_50}><p>6h 6s 6c 6d Js</p></div>
                    </div>
                    <div className={s.row}>
                        <div className={s.w_50}><p className={s.textLeft}>Flush</p></div>
                        <div className={s.w_50}><p>As Ks Js 9s 7s</p></div>
                    </div>
                    <div className={s.row}>
                        <div className={s.w_50}><p className={s.textLeft}>Full House</p></div>
                        <div className={s.w_50}><p>9s 9c 9d 8c 8s</p></div>
                    </div>
                    <div className={s.row}>
                        <div className={s.w_50}><p className={s.textLeft}>Straight</p></div>
                        <div className={s.w_50}><p>Kd Qc Jd Ts 9c</p></div>
                    </div>
                    <div className={s.row}>
                        <div className={s.w_50}><p className={s.textLeft}>Three of a Kind</p></div>
                        <div className={s.w_50}><p>Ac Ah Ad Js 8d</p></div>
                    </div>
                    <div className={s.row}>
                        <div className={s.w_50}><p className={s.textLeft}>Two Pair</p></div>
                        <div className={s.w_50}><p>Th Tc 9h 9h Jc</p></div>
                    </div>
                    <div className={s.row}>
                        <div className={s.w_50}><p className={s.textLeft}>One Pair</p></div>
                        <div className={s.w_50}><p>Jd Js Ac 8c 7h</p></div>
                    </div>
                    <div className={s.row}>
                        <div className={s.w_50}><p className={s.textLeft}>High Card</p></div>
                        <div className={s.w_50}><p>Ks Qh Tc 8s 7d</p></div>
                    </div>
                </div>
            </div>
        <p className={s.stepSubSubtitle}>Betting Limits</p>
        <p className={s.stepParagraph}>
            Although in theory you can play Short Deck Hold’em at Fixed and Pot Limit, the game is almost exclusively played at No Limit. In other words, you can bet any amount up to the maximum number of chips in front of you.
        </p>
        <p className={cx(s.stepSubtitle, s.mt_60)}>
            <span className={s.stepSubtitleBlue}>4. </span>Short Deck Trivia
        </p>
        <ul className={cx(s.list,s.mb_45)}>
            <li className={s.listItem}>
                You will be dealt pocket aces once every 105 hands in a Short Deck game.
            </li>
            <li className={s.listItem}>
                The odds of flopping a set with a pocket pair are 18%.
            </li>
            <li className={s.listItem}>
                The game made its debut at the 2018 Triton Poker Series in Montenegro. Phil Ivey - who else? - took down the 61 player HKD 250,000 tournament for a first prize of HKD 4.74 million (approximately $600,000).
            </li>
            <li className={s.listItem}>
                At the same series, a HKD 1 million buy in Short Deck event was also played. Jason Koon triumphed in a 103 strong field. He took down HKD 28.1 million for the privilege (approximately $3.68 million).
            </li>
        </ul>
    </Fragment>,
    navigationreferenceid: `#${pokerGamesNavigationData[1].id}`,
};